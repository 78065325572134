import { Injectable, inject } from '@angular/core';
import { type CompanySubscription } from '../models/company-subscription';
import { HttpService } from './http.service';
import { type Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanySubscriptionService {
  private readonly http = inject(HttpService);

  public getCompanySubscription(companyId: number): Observable<CompanySubscription> {
    return this.http.getV2(`/api/companies/${companyId}/subscriptions`);
  }
}
